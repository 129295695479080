

import {Link, Trans, useTranslation, useI18next}
from 'gatsby-plugin-react-i18next';



import React from "react"
import i18next from '../i18n/config';
// import ImgHeader from "../img/A_glowna_1/tlo_glowna.jpg";
import ImgOurCoffeeBack from "../img/D_kawa_ziarnista_18/iko_powrot_nasze_kawy.png";

import rodoMail from "../img/icon/rodo2.png";


export default function Header(props) {



    return (
            <React.Fragment>
                <section id="PrivacyPolicy" class="  firstSection">
                    <h1>{i18next.t('PrivacyPolicyH1')}</h1>
                    <p>


                        {i18next.t('lang') == "pl" && (
                            <div >
                                <p>Ciasteczka - ang. Cookies to niewielkie pliki tekstowe wysyłane przez serwis internetowy, który odwiedza internauta, do urządzenia internauty. Ciasteczko w żadnym wypadku nie umożliwia identyfikacji osoby odwiedzającej witrynę i nie są w nim zapisywane żadne informacje, mogące taką identyfikację umożliwić.</p>
                                <p>Używamy ciasteczek dla poprawy Państwa wygody oraz po to, by zapewnić pełną funkcjonwalność serwisu i w celach statystycznych. Cookies używają też serwisy, do których się odwołujemy pokazując np. multimedia, zbierając statystyki, wyswietlając reklamy.</p>
                                <p>W przeglądarce internetowej można zmienić ustawienia dotyczące ciasteczek. Brak zmiany tych ustawień oznacza Państwa akceptację na stosowanie przez niniejszy serwis ciasteczek.</p>
                                <p>Serwisy obce, z których materiały przedstawiamy, mogą także używać cookies, które umożliwiają logowanie się, oraz służą dostarczaniu reklam odpowiadających upodobaniom i zachowaniom użytkownika.</p>
                                <p>Wykorzystujemy statystyki odwiedzin Google Analitycs, które pomagają nam zrozumieć, w jaki sposób Użytkownicy Serwisu korzystają z naszego sklepu, co umożliwia ulepszanie jego struktury i zawartości. Statystyki zapisują własne pliki cookies, szczegółowa polityka w tym zakresie dostępna jest na stronie <a target="_blank" href="http://www.google.com/intl/pl/policies/privacy/">http://www.google.com/intl/pl/policies/privacy/</a></p>
                                <p>Stosujemy system reklamowy Google Adwords, który może zapisywać własne pliki cookies na urządzeniu klienta i wykorzystywać je np. w celu lepszego dostosowania reklam wyświetlanych użytkownikowi lub w celach statystycznych. Szczegółowa polityka w tym zakresie jest dostępna na stronie <a target="_blank" href="http://www.google.com/intl/pl/policies/privacy/">http://www.google.com/intl/pl/policies/privacy/</a></p>
                                <p>Filmy umieszczone w naszym serwisie są przy wykorzystaniu serwisów youtube.com oraz vimeo.com, które zapisują własne pliki cookies zawierające m.in. preferencje użytkownika, pozwalające się zalogować a także cookies pozwalające na uzależnienie wyświetlania reklam w zależności od zachowań użytkownika. Szczegóły opisane są w politykach prywatności tych serwisów prywatności <a target="_blank" href="http://www.google.pl/intl/pl/policies/privacy/">http://www.google.pl/intl/pl/policies/privacy/</a> oraz <a target="_blank" href="http://vimeo.com/cookie_policy">http://vimeo.com/cookie_policy</a></p>
                                <p>Poziom ochrony przed cookies można ustawić w swojej przeglądarce internetowej – aż do całkowitego blokowania plików cookies. Zwiększa to poziom bezpieczeństwa i ochrony danych, ale może także uniemożliwiać niektóre funkcje, np. zalogowanie się na konto pocztowe.</p>
                                <p>Poniższe strony zawierają instrukcję zarządzania plikami cookies w wybranej przeglądarce:</p>
                                <br />
                                <ul>
                                    <br /> <li><strong>Mozilla Firefox</strong><br /> <a target="_blank" href="http://support.mozilla.org/pl/kb/ciasteczka">http://support.mozilla.org/pl/kb/ciasteczka</a></li>
                                    <br /> <li><strong>Microsoft Internet Explorer</strong><br /> <a target="_blank" href="http://windows.microsoft.com/pl-pl/windows-vista/block-or-allow-cookies">http://windows.microsoft.com/pl-pl/windows-vista/block-or-allow-cookies</a></li>
                                    <br /> <li><strong>Google Chrome</strong><br /> <a target="_blank" href="http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647">http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647</a></li>
                                    <br /> <li><strong>Apple Safari</strong><br /> <a target="_blank" href="http://support.apple.com/kb/HT1677?viewlocale=pl_PL">http://support.apple.com/kb/HT1677?viewlocale=pl_PL</a></li>
                                    <br /> <li><strong>Opera</strong><br /> <a target="_blank" href="http://help.opera.com/Windows/12.10/pl/cookies.html">http://help.opera.com/Windows/12.10/pl/cookies.html</a></li>
                                </ul>

                                <br /><br />

                                Niniejszym informujemy, iż Administratorem Państwa danych osobowych jest Instanta Sp. z o.o. Administrator  przetwarza dane osobowe w celu wykonywania obowiązków wynikających z umów oraz innych czynności pomiędzy stronami. Jednocześnie informujemy o przysługującym prawie dostępu do tych danych oraz prawie ich poprawiania i żądania ich usunięcia, poprzez przesłanie stosownej wiadomości do Administratora w wiadomości email: <img src={rodoMail} /> lub listownie Instanta sp. z o.o 44-240 Żory Kawowa 3. „Oświadczamy, że Spółka Instanta posiada status dużego przedsiębiorstwa w rozumieniu przepisów ustawy o przeciwdziałaniu nadmiernym opóźnieniom w transakcjach handlowych (Dz.U. z 2019r. poz. 1649 zwana dalej Ustawą) oraz załącznika I do Rozporządzenia Komisji (UE) Nr 651/2014 z dnia 17 czerwca 2014r. „
                                <br /><br />
                                Wszelkie prawa zastrzeżone
                                <br /><br />
                                NIP PL 6511563428; Regon 276733272; BDO: 000011027; Numer KRS: 0000067391; Sąd Rejonowy w Gliwicach; X Wydział Gospodarczy Krajowego Rejestru Sądowego; Wysokość kapitału zakładowego 400 000,00zł
                                <br /><br />

                            </div>

                                )}




                    {i18next.t('lang') == "en" && (
                            <div>
                                <p>This site saves small text files called cookies to your browser&rsquo;s directory or program subfolders. Stored cookies are not capable of identifying any person visiting our site. Each cookie has an expiry date which is set by the author, and will be automatically deleted when the expiry date is reached. Cookies can be divided into two groups, session cookies, which expire as soon as the browser is closed, and persistent cookies, which have a set expiry date.</p>
                                <p>In accordance with the amendment of 16<sup>th</sup> November 2012 of Polish Telecommunication law, <a href="http://www.dziennikustaw.gov.pl/du/2012/1445">http://www.dziennikustaw.gov.pl/du/2012/1445</a>  by continuing to use our site, you agree to the placement of cookies on your device. If you choose not to receive our cookies, we cannot guarantee that your experience will be as fulfilling as it would otherwise be.</p>
                                <p>If you would like to change your browser settings in order to forbid cookies, please follow these instructions for your chosen software.</p>
                                <br /> <p>Google Chrome</p>
                                <p><a href="http://support.google.com/chrome/bin/answer.py?hl=en-GB&amp;hlrm=pl&amp;answer=95647">http://support.google.com/chrome/bin/answer.py?hl=en-GB&amp;hlrm=pl&amp;answer=95647</a></p>
                                <br /> <p>Mozilla Firefox</p>
                                <p><a href="http://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer">http://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer</a></p>
                                <br /> <p>Microsoft Internet Explorer</p>
                                <p><a href="http://windows.microsoft.com/en-us/internet-explorer/delete-manage-cookies#ie=ie-10">http://windows.microsoft.com/en-us/internet-explorer/delete-manage-cookies#ie=ie-10</a></p>
                                <br /> <p>Opera</p>
                                <p><a href="http://www.opera.com/help/tutorials/security/privacy/" target="_blank">http://www.opera.com/help/tutorials/security/privacy/</a></p>
                                <br /><p>Apple Safari</p>
                                <p><a href="http://support.apple.com/kb/ht1677" target="_blank">http://support.apple.com/kb/ht1677</a></p>
                                <br /><p>This page in the cookie files saves only information about the preferred language in which the page is displayed and about the fact that the privacy policy bar is hidden.</p>
                                <p>Statistical information is also collected, as well as information which allow us to understand how our users use our site. This helps us to make the content and the structure of the webpage better. In order to do this, Google Analytics and stat24 programmes are used, which allow us to differentiate between computer systems at the same time. In order to find out more about the ways of using cookies and the privacy policies of the aforementioned services please go to the following webpages:</p>
                                <p><a href="http://www.google.pl/intl/pl/policies/technologies/types/" target="_blank">http://www.google.pl/intl/pl/policies/technologies/types/</a></p>

                                <p><br />


                                    We hereby inform you that the Administrator of your personal data is Instanta Sp. z o.o. The administrator processes personal data in order to perform obligations under the contracts and activities between the parties. At the same time, we inform you about the right of access to these data and the right to correct them and request their removal, by sending an appropriate message to the Administrator in the email: <img src={rodoMail} /> or in writing to: Instanta sp. o.o. 44-240 Żory, ul. Kawowa 3.
                                    <br /><br />
                                    All rights reserved

                                    <br /><br />

                                    NIP PL 6511563428; Regon 276733272; BDO: 000011027;Numer KRS: 0000067391; Sąd Rejonowy w Gliwicach; X Wydział Gospodarczy Krajowego Rejestru Sądowego; amount of initial capital 400 000,00zł
                                    <br /><br />

                                </p>
                            </div>


                                )
                    }








                    {i18next.t('lang') == "es" && (
                            <div>
                                <p>Este sitio guarda un pequeño archivo de texto llamados cookies para directorios o&nbsp;programas de subcarpetas en su navegador. Las cookies almacenadas no son capaces de identificar a&nbsp;cualquier persona que visite nuestro sitio. Cada cookie tiene una fecha de expiración fijada por el autor, y se eliminará automáticamente cuando se alcanza la fecha de caducidad. Las cookies se pueden dividir en dos grupos, cookies de sesión, que expiran tan pronto como se cierra el navegador y las cookies persistentes, que disponen de una fecha de caducidad.</p>
                                <p>De acuerdo con la enmienda del 16 de noviembre de 2012 de la ley polaca de Telecomunicaciones, http://www.dziennikustaw.gov.pl/du/2012/1445 al continuar usando nuestro sitio, usted está de acuerdo con la colocación de cookies en su dispositivo. Si no opta por recibir nuestras cookies, no podemos garantizar que su experiencia será tan satisfactoria como sería de otro modo.</p>
                                <p>La información estadística también se recoge, así como la información que nos permiten comprender cómo nuestros usuarios utilizan nuestro sitio. Esto nos ayuda a&nbsp;hacer el contenido y la estructura de la página web mejor. Con el fin de hacer esto, Google Analytics y programas stat24 se utilizan, lo que nos permite diferenciar entre sistemas informáticos al mismo tiempo. Con el fin de obtener más información sobre las formas de utilización de cookies y las políticas de privacidad de los servicios antes mencionados por favor vaya a&nbsp;las siguientes páginas <a target="_blank" href="http://www.google.com/intl/pl/policies/privacy/">http://www.google.com/intl/pl/policies/privacy/</a></p>
                                <p>Utilizamos el sistema de publicidad de Google Adwords, que puede almacenar sus propias cookies en el dispositivo del cliente y utilizarlos, por ejemplo, con el fin de adaptar mejor los anuncios para el usuario o&nbsp;para fines estadísticos. Política detallada al respecto está disponible en la <a target="_blank" href="http://www.google.com/intl/pl/policies/privacy/">http://www.google.com/intl/pl/policies/privacy/</a></p>
                                <p>Vídeos que podrá encontrar en nuestro sitio están utilizando youtube.com y vimeo.com, los que escriben sus propias cookies que contienen, entre otros, las preferencias del usuario, lo que permite inicio de sesión y cookies permiten anuncios basados en el comportamiento del usuario. Los detalles se describen en las políticas de privacidad de los sitios de privacidad <a target="_blank" href="http://www.google.pl/intl/pl/policies/privacy/">http://www.google.pl/intl/pl/policies/privacy/</a> y <a target="_blank" href="http://vimeo.com/cookie_policy">http://vimeo.com/cookie_policy</a>)</p>
                                <p>El nivel de protección de las cookies puede ajustarse en su navegador web - para bloquear por completo las cookies. Esto aumenta el nivel de seguridad y protección de datos, pero también puede bloquear ciertas funciones, tales como, ingreso a&nbsp;su cuenta de correo electrónico.</p>
                                <p>Si desea cambiar la configuración del navegador con el fin de prohibir las cookies, sigue estas instrucciones para su software elegido:</p>
                                <br />
                                <ul>
                                    <li><strong>Mozilla Firefox</strong><br /> <a target="_blank" href="http://support.mozilla.org/es/kb/cookies-information-websites-store-on-your-computer">http://support.mozilla.org/es/kb/cookies-information-websites-store-on-your-computer</a></li>
                                    <br /> <li><strong>Microsoft Internet Explorer</strong><br /> <a target="_blank" href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies">https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies</a></li>
                                    <br /> <li><strong>Google Chrome</strong><br /> <a target="_blank" href="http://support.google.com/chrome/bin/answer.py?hl=en-GB&amp;hlrm=pl&amp;answer=95647">http://support.google.com/chrome/bin/answer.py?hl=en-GB&amp;hlrm=pl&amp;answer=95647</a></li>
                                    <br /> <li><strong>Apple Safari</strong><br /> <a target="_blank" href="http://support.apple.com/kb/ht1677">http://support.apple.com/kb/ht1677</a></li>
                                    <br /> <li><strong>Opera</strong><br /> <a target="_blank" href="http://www.opera.com/help/tutorials/security/privacy/">http://www.opera.com/help/tutorials/security/privacy/</a></li>
                                </ul>

                                Le informamos que el Administrador de sus datos personales es Instanta Sp. zoo. El administrador procesa los datos personales para cumplir con las obligaciones derivadas de los contratos y actividades entre las partes. A su vez, le informamos sobre el derecho de acceso a estos datos y el derecho a subsanarlos y solicitar su supresión, mediante el envío de un mensaje oportuno al Administrador en el correo electrónico: <img src={rodoMail} /> o por escrito a: Instanta sp. o.o. 44-240 Żory, ul. Kawowa 3.
                                <br /><br />
                                Reservados todos los derechos
                                <br /><br />
                                NIP PL 6511563428; Regon 276733272; BDO: 000011027;Numer KRS: 0000067391; Sąd Rejonowy w Gliwicach; X Wydział Gospodarczy Krajowego Rejestru Sądowego; cantidad de capital inicial 400 000,00zł
                                <br /><br />

                            </div>


                                )
                    }












                    </p>
                </section>
            </React.Fragment>

            )


}

